import React, { useState, useEffect, useRef } from 'react';
import Loader from 'react-loaders';
import './index.scss';
import AnimatedLetters from '../AnimatedLetters';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const [isLoading, setIsLoading] = useState(false);
    const refForm = useRef()
    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 4000)
    }, [])

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('gmail', 'contact_form', refForm.current, '9GvgPo12eIoQ4h_qu')
            .then(() => {
                setIsLoading(false);
                toast.success('Email sent successfully')
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error('Failed to send email. Please try again later.')
                console.log(error);
            });
    };
    return (
        <>
            <div className='container contact-page'>
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters letterClass={letterClass} strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']} idx={15} />
                    </h1>
                    <p>
                        I am
                        looking forward to working as a Software Engineer that will allow me to apply my technical skills so that
                        I can contribute to the growth of the organization also to enhance my skills.
                    </p>
                    <div className='contact-form'>
                        <form ref={refForm} onSubmit={sendEmail}>
                            <ul>
                                <li className='half'>
                                    <input type='text' name='name' placeholder='Name*' required />
                                </li>
                                <li className='half'>
                                    <input type='email' name='email' placeholder='Email*' required />
                                </li>
                                <li>
                                    <input type='text' name='subject' placeholder='Subject*' required />
                                </li>
                                <li>
                                    <textarea name='message' placeholder='Message*' required></textarea>
                                </li>
                                <li>
                                    {!isLoading ? <input type='submit' className='flat-button' value='SEND' disabled={isLoading} />
                                        : <input type='submit' className='flat-button' value='Sending...' disabled />}
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer theme="dark"
                position="top-center" />
            <Loader type='ball-grid-pulse' />
        </>
    )
}
export default Contact;