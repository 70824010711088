import './index.scss';
import { useState, useEffect } from 'react';
import AnimatedLetters from '../AnimatedLetters';
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngular, faCss3, faGitAlt, faHtml5, faJsSquare, faReact } from '@fortawesome/free-brands-svg-icons';
import Loader from 'react-loaders';

const Portfolio = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = ['S', 'k', 'i', 'l', 'l', 's', ' ', '&', ' ', 'E', 'x', 'p', 'e', 'r', 'i', 'e', 'n', 'c', 'e', 's']
    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])
    return (
        <>
            <div className='container portfolio-page'>
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={nameArray}
                            idx={15}
                        />
                    </h1>

                    <p>
                        • Languages: C, Python, JavaScript, Typescript, Java, C++ <br />
                        • Web Development: HTML, CSS, DOM, ReactJS, Angular, NodeJS, ExpressJS, .NET Framework <br />
                        • Database Management System: MySQL, MS SQL, PostgreSQL<br />
                        • Cloud Computing: AWS (Compute Engines, Apache Spark, AWS SageMaker, AWS Lambda AWS IAM, Route 53, Amazon RDS, AWS Cloudwatch)<br />
                        • Operating Systems: Linux, Windows, macOS, iOS, Android<br />
                        • Design Software: Figma, Zeplin, StarUML<br />
                        • Tools: GitHub, GitLab, Bitbucket, Jira, Confluence, SonarQube<br />
                        • Testing Framework: JUnit4<br />
                        • Microsoft Office Suite: Word, PowerPoint<br />
                    </p>
                    <p>
                        <b>Software Engineer, Full Stack Web Developer, <a target='_blank' rel='noreferrer' href='https://experionglobal.com/'>Experion Technologies.</a></b> <br />
                        Involved in a project called QuVa, a production-level medicine ordering portal.<br />
                        I actively contributed to the development and maintenance of <a target="_blank" rel="noreferrer" href='https://portal.quvapharma.com/#/auth/login?returnUrl=%2Fdashboard'>QuVa</a>, a production-level medicine ordering portal built using Angular Library and .NET Framework.
                        I collaborated closely with the team to understand the existing project and its business flows, enabling me to design and implement various add-on features seamlessly.
                        Throughout the project, I demonstrated strong problem-solving skills by promptly identifying and resolving production-level bugs, ensuring code quality and stability. Additionally,
                        I proposed new project ideas that added significant business value to the portal, enhancing its functionality and user experience.
                        <p>
                            Involved in a project called <a target='_blank' rel='noreferrer' href='https://www.janssen.com/'>Janssen</a>, a healthcare project for data analysis. <br />
                            In the Janssen healthcare project, I played a key role in data analysis and development.
                            I created proof-of-concept (POC) solutions using Python, which included fetching data from major Electronic Health Records (EHR) providers in FHIR standard, incorporating proper authorization and authentication mechanisms.
                            The successful completion of these POCs provided valuable insights to the development and business analytics teams, enabling them to assess the project's scope and feasibility from various perspectives.
                            This contribution significantly aided in shaping the project's direction and ensuring its alignment with the customer's requirements.
                        </p>
                    </p>
                </div>

                <div className='stage-cube-cont'>
                    <div className='cubespinner'>
                        <div className='face1'>
                            <FontAwesomeIcon icon={faAngular} color='#DD0031' />
                        </div>
                        <div className='face2'>
                            <FontAwesomeIcon icon={faHtml5} color='#F06529' />
                        </div>
                        <div className='face3'>
                            <FontAwesomeIcon icon={faCss3} color='#28A4D9' />
                        </div>
                        <div className='face4'>
                            <FontAwesomeIcon icon={faReact} color='#5ED4F4' />
                        </div>
                        <div className='face5'>
                            <FontAwesomeIcon icon={faJsSquare} color='#EFD81D' />
                        </div>
                        <div className='face5'>
                            <FontAwesomeIcon icon={faGitAlt} color='#EC4D28' />
                        </div>
                    </div>
                </div>
            </div>
            <Loader type="ball-grid-pulse" />
        </>
    );
}

export default Portfolio;
