import { useState, useEffect } from 'react';
import AnimatedLetters from '../AnimatedLetters';
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngular, faCss3, faGitAlt, faHtml5, faJsSquare, faReact } from '@fortawesome/free-brands-svg-icons';
import Loader from 'react-loaders';

const About = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])
    return (
        <>
            <div className='container about-page'>
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
                            idx={15}
                        />
                    </h1>

                    <p>
                        I am a CS Graduate Student at Virginia Tech.
                        I was a Software Engineer for 2 years at <a target='_blank' rel='noreferrer' href='https://experionglobal.com/'>Experion Technologies.</a>
                        I am a Full Stack Developer with experience in React, Node, Express, Angular, .NET Framework and Python.
                    </p>
                    <p>
                        Presented a paper on "Medi-Chain: A Blockchain-based System for Detecting Counterfeit Drugs" at the 2022 International Conference on Research and Innovation in Science, Technology and Management (ICRISTM-22).
                        Secured a position within the Top 50 teams for Hac'KP, an International Hackathon conducted by Kerala Police CyberDome in 2021.
                    </p>
                    <p>
                        I am looking forward to working as a Software Engineer that will allow me to apply my technical skills so that
                        I can contribute to the growth of the organization also to enhance my skills.
                    </p>
                    <p>
                        I am learning every day to allow the space between where I am and where I want to be to inspire me.
                        <br></br>I am a student, a friend, a partner, a creator and more...
                    </p>


                </div>

                <div className='stage-cube-cont'>
                    <div className='cubespinner'>
                        <div className='face1'>
                            <FontAwesomeIcon icon={faAngular} color='#DD0031' />
                        </div>
                        <div className='face2'>
                            <FontAwesomeIcon icon={faHtml5} color='#F06529' />
                        </div>
                        <div className='face3'>
                            <FontAwesomeIcon icon={faCss3} color='#28A4D9' />
                        </div>
                        <div className='face4'>
                            <FontAwesomeIcon icon={faReact} color='#5ED4F4' />
                        </div>
                        <div className='face5'>
                            <FontAwesomeIcon icon={faJsSquare} color='#EFD81D' />
                        </div>
                        <div className='face5'>
                            <FontAwesomeIcon icon={faGitAlt} color='#EC4D28' />
                        </div>
                    </div>
                </div>
            </div>
            <Loader type="ball-grid-pulse" />
        </>
    )
}

export default About;